<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      FAQ: "FAQ",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      FAQ: "FAQ",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    VclList,
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      faqs: null,
    };
  },
  methods: {
    getList() {
      api
        .get("faq")
        .then((response) => {
          this.loading = false;
          if (response.data.status == "success") {
            this.faqs = response.data.list;
          } else {
            this.empty = true;
          }
        })
        .catch((error) => {
          this.errored = error;
        });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("FAQ") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="empty">{{ t("No records found.") }}</div>
            <div v-else>
              <div role="tablist">
                <vcl-list v-if="loading" class="col-lg-6"></vcl-list>
                <b-card
                  no-body
                  class="mb-1 shadow-none"
                  e
                  v-for="(faq, index) in faqs"
                  :key="index"
                >
                  <b-card-header header-tag="header" role="tab">
                    <h6 class="m-0">
                      <a
                        v-b-toggle="'accordion-' + faq.id"
                        class="text-dark"
                        href="javascript: void(0);"
                        >{{ faq.question }}</a
                      >
                    </h6>
                  </b-card-header>
                  <b-collapse
                    :id="'accordion-' + faq.id"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text v-html="faq.answer"></b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
